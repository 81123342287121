.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  width: 90%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  scroll-behavior: smooth;
  margin: 0.25rem;
}

.card > p {
  font-size: 16px;
}

@media (min-width: 650px) {
  .container {
    display: flex;
    flex-direction: row;
    height: auto;
    overflow-x: scroll;
    align-items: center;
    justify-content: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    width: 300px;
    height: 200px;
    margin: 1rem auto;
    padding: 1rem;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.2);
    scroll-behavior: smooth;
    margin: 0.25rem;
  }
}
