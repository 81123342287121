* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1.6rem;
}

h1 {
  font-size: 6rem;
}

.app {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  overflow: hidden;
}

.app::before {
  content: "";
  background: url("./assets/sunset.jpg") no-repeat center center/cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.container {
  max-width: 700px;
  height: 700px;
  margin: auto;
  padding: 0 1rem;
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app .top {
  width: 100%;
  margin: 1rem auto;
}

.app .description {
  position: relative;
  right: -90%;
  transform-origin: 0 0;
  transform: rotate(270deg);
}

.app .bottom {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 650px) {
  .app .bottom {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
    margin: 1rem 3rem;
    padding: 1rem;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.bold {
  font-weight: 700;
}

.app .search {
  text-align: center;
  padding: 1rem;
}

.app input {
  padding: 0.7rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.1);
  color: #f8f8f8;
  margin-right: 1rem;
}

::placeholder {
  color: #f8f8f8;
}
