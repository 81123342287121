.container {
  height: 300px;
  display: flex;
  flex-direction: column;
}

.top {
  width: 100%;
  margin: 1rem auto;
  padding: 0.5rem;
}

.noData {
  width: 100%;
  padding: 2rem;
  color: #f8f8f8;
  font-size: 24px;
  text-align: center;
}

.location {
  font-size: 1.4rem;
}

.temp {
  font-size: 1.4rem;
}

.temp > h1 {
  font-size: 4rem;
}

.description {
  position: relative;
  right: -90%;
  transform-origin: 0 0;
  transform: rotate(270deg);
}
