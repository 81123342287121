.container {
  text-align: center;
  padding: 1rem;
  display: flex;
}

.formInput {
  padding: 0.5rem;
}

.formError {
  color: red;
  padding-top: 0.25rem;
  margin-bottom: 0.5rem;
}

.submitButton {
  padding: 0.25rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.2);
  color: #f8f8f8;
  margin-right: 1rem;
}
