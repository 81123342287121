.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.25rem 0.25rem;
}

.button {
  padding: 10px 20px;
  background-color: #ccc;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}

.toggled {
  background-color: green;
  color: white;
}
